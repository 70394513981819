<template>
<div class="autocomplete sr-custom-input" :class="isSaving ? 'field-saved' : ''">
  <input type="text"
    @input="onChange"
    v-model="search"
    @keyup.down="onArrowDown"
    @keyup.up="onArrowUp"
    @keyup.enter="onEnter"
    @keyup="onKeyup"
    class="form-control w-100"
    placeholder="ex: Portugal" />
  <i class="fas fa-check"></i>
  <div v-if="showError" class="error-msg position-absolute">Value is required</div>
  <ul v-show="isOpen" class="autocomplete-results shadow">
    <li class="loading" v-if="isLoading">
      Loading results...
    </li>
    <li v-else
      v-for="(result, i) in results"
      :key="i"
      @click="setResult(result)"
      class="autocomplete-result"
      :class="{ 'is-active': i === arrowCounter }">
      {{ result }}
    </li>
  </ul>
</div>
</template>

<script>
import eventHub from '../utils/eventHub'

export default {
  name: 'autocomplete',
  props: {
    value: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    isSaving: {
      type: Boolean,
      required: false,
      default: false
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: 0,
      showError: false
    }
  },
  methods: {
    onChange () {
      if (this.isAsync) {
        this.isLoading = true
      } else {
        this.filterResults()
        this.isOpen = true
      }

      this.showError = true
    },
    filterResults () {
      this.results = this.items.filter(item => {
        return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },
    setResult (result) {
      this.search = result
      this.isOpen = false
      if (this.items.includes(this.search)) {
        this.showError = false
        eventHub.$emit('set-country', { country: result })
      }
    },
    onKeyup (event) {
      if (this.search) {
        const foundedItem = this.items.find(
          (item) => item.toLowerCase() === this.search.toLowerCase()
        )
        if (foundedItem) this.setResult(foundedItem)
      }
    },
    onArrowDown (evt) {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp () {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter (event) {
      this.search = this.results[this.arrowCounter]
      this.isOpen = false
      this.arrowCounter = -1
      if (this.search) {
        this.setResult(this.search)
      } else {
        if (this.results && this.results['0']) {
          this.setResult(this.results['0'])
        }
      }
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    }
  },
  watch: {
    items (val, oldValue) {
      if (val.length !== oldValue.length) {
        this.results = val
        this.isLoading = false
      }
    }
  },
  mounted () {
    if (this.value) {
      this.search = this.value
    }
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>
