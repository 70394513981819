<template>
  <div class="main">
    <div class="wrapper pt-4">
      <div
        class="surface bg-white shadow d-flex flex-column px-4 pt-5 pb-4 mt-4 w-100 top"
      >
        <h2 class="mb-5 text-center text-sm-left">Sign up for SureFooted</h2>
        <form class="d-flex flex-column" @submit.prevent="submit" novalidate>
          <template v-if="step">
            <div class="form-row mb-3">
              <div class="form-group col-md-6">
                <label
                  class="auth-labels text-uppercase small pl-1"
                  for="first_name"
                  >First name*</label
                >
                <div :class="{ error: v$.first_name.$errors.length }">
                  <input
                    type="text"
                    class="form-control"
                    name="first_name"
                    v-model="v$.first_name.$model"
                    @input="v$.first_name.$touch"
                    @keyup="initialCheck"
                    placeholder="ex: John"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.first_name.$errors"
                    :key="error.$uid"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label
                  class="auth-labels text-uppercase small pl-1"
                  for="last_name"
                  >Last name*</label
                >
                <div :class="{ error: v$.last_name.$errors.length }">
                  <input
                    type="text"
                    class="form-control"
                    name="last_name"
                    v-model="v$.last_name.$model"
                    @input="v$.last_name.$touch"
                    @keyup="initialCheck"
                    placeholder="ex: Doe"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.last_name.$errors"
                    :key="error.$uid"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="auth-labels text-uppercase small pl-1" for="email"
                >Email address*</label
              >
              <div :class="{ error: v$.email.$errors.length }">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="v$.email.$model"
                  @input="v$.email.$touch"
                  @keyup="initialCheck"
                  placeholder="ex: john@example.com"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.email.$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="auth-labels text-uppercase small pl-1" for="passwd"
                >Password*</label
              >
              <div :class="{ error: v$.passwd.$errors.length }">
                <input
                  type="password"
                  class="form-control"
                  name="passwd"
                  v-model="v$.passwd.$model"
                  @input="v$.passwd.$touch"
                  @keyup="initialCheck"
                  placeholder="ex: Password123@"
                />
                <div
                  class="input-errors"
                  v-for="error of passwordValidation.errors"
                  :key="error.$uid"
                >
                  <div class="error-msg" v-if="v$.passwd.$model != ''">
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt--1">
              <label
                class="auth-labels text-uppercase small pl-1"
                for="confirmation_passwd"
                >Confirm Password*</label
              >
              <div :class="{ error: v$.confirmation_passwd.$errors.length }">
                <input
                  type="password"
                  class="form-control"
                  name="confirmation_passwd"
                  v-model="v$.confirmation_passwd.$model"
                  @input="v$.confirmation_passwd.$touch"
                  @keyup="initialCheck"
                  placeholder="ex: Password123@"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.confirmation_passwd.$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="form-group mb-3">
              <label class="auth-labels text-uppercase small pl-1" for="role_id"
                >Account type*</label
              >
              <div class="d-flex">
                <div class="position-relative custom-input">
                  <input
                    type="radio"
                    class="btn btn-primary"
                    name="role_id"
                    checked
                    @change="role_id = 4"
                  />
                  <label for="tour_operator"
                    ><i class="fas fa-route mr-2"></i>Tour Operator</label
                  >
                </div>
                <div class="position-relative custom-input">
                  <input
                    type="radio"
                    class="btn btn-primary"
                    name="role_id"
                    @change="role_id = 3"
                  />
                  <label for="travel_agent"
                    ><i class="fas fa-road mr-2"></i>Travel Agent</label
                  >
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label
                class="auth-labels text-uppercase small pl-1"
                for="company_name"
                >Company name*</label
              >
              <div :class="{ error: v$.company_name.$errors.length }">
                <input
                  type="text"
                  class="form-control"
                  name="company_name"
                  v-model="v$.company_name.$model"
                  @input="v$.company_name.$touch"
                  @keyup="finalCheck"
                  placeholder="ex: Facebook Inc."
                />
                <div
                  class="input-errors"
                  v-for="error of v$.company_name.$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label
                class="auth-labels text-uppercase small pl-1"
                for="country_id"
                >Country*</label
              >
              <autocomplete :items="countries" @keyup="finalCheck" />
            </div>
            <div class="form-group mb-3">
              <label
                class="auth-labels text-uppercase small pl-1"
                for="url_conditions"
                >Booking Conditions link on your own site</label
              >
              <div>
                <input
                  type="text"
                  class="form-control"
                  name="url_conditions"
                  v-model="v$.url_condition.$model"
                  @input="event => this.url_conditions = event.target.value"
                  @keyup="finalCheck"
                  placeholder="ex: https://www.facebook.com/"
                />
              </div>
            </div>
            <div class="form-group mt--1" id="slim-cropper-wrapper">
              <label class="auth-labels text-uppercase small pl-1" for="slim"
                >Company logo</label
              >
              <slim-cropper
                class="slim-wrapper form-control w-100 d-flex p-4 h-auto bg-white"
                :options="slimOptions"
              >
                <div ref="preview" class="img-preview rounded-circle mb-2">
                  A
                </div>
                <div class="d-flex flex-column ml-4">
                  <div class="position-relative custom-input w-100">
                    <input
                      type="file"
                      id="slim-image-container"
                      accept="image/*"
                      class="btn btn-primary"
                      name="slim"
                      ref="file"
                    />
                    <label for="slim" class="file-uploader"
                      ><i class="far fa-2x fa-image mr-2"></i
                      ><span>Upload or drop a file...</span></label
                    >
                    <label for="slim" class="file-uploader-mobile"
                      ><i class="far fa-2x fa-image mr-2"></i
                    ></label>
                  </div>
                  <small class="mt-3 file-helper w-100"
                    >For optimum results please select a JPG or PNG that is
                    square in proportions and a minimum of<br />300 x
                    300px.</small
                  >
                </div>
              </slim-cropper>
            </div>
          </template>
          <div class="mt-3 d-flex align-items-center">
            <p class="text-muted small mb-0 pl-1">
              Step {{ step ? "1" : "2" }} of 2
            </p>
            <button
              v-if="step && enabledNextButton"
              type="submit"
              class="btn btn-primary ml-auto"
              @click.prevent="nextStep"
            >
              Next Step
            </button>
            <span v-else class="ml-auto">
              <button
                type="button"
                class="btn btn-primary"
                @click.prevent="goBack"
              >
                Back
              </button>
              <button
                v-if="enabledCreateButton"
                type="submit"
                class="btn btn-primary ml-3"
                @click.prevent="signup"
              >
                Create Account
              </button>
            </span>
          </div>
          <b-modal hide-header ok-only no-stacking v-model="errormsg">
            <div class="error-msg2">{{ msgerror }}</div>
          </b-modal>
        </form>
      </div>
      <p
        v-if="!step"
        class="font-weight-normal mb-4 text-center text-muted mt-3"
      >
        By creating an account, you agree to
        <span class="text-primary hover" @click="terms">our terms</span> and
        <span class="text-primary hover" @click="privacy">privacy policy</span>.
      </p>
      <div
        class="surface d-flex align-items-center justify-content-center mt-3"
      >
        <p class="font-weight-normal mb-0 mr-2">Already have an account?</p>
        <router-link
          class="text-primary font-weight-bold ml-2"
          :to="{ name: 'login', params: {} }"
          >Log in</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
// v-show="enabledNextButton"
import Slim from '../vendor/slim/slim.vue'
import Autocomplete from '../components/Autocomplete.vue'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'
import eventHub from '../utils/eventHub'

export default {
  name: 'signup',
  components: {
    'slim-cropper': Slim,
    autocomplete: Autocomplete
  },
  data () {
    return {
      step: true,
      enabledNextButton: false,
      enabledCreateButton: false,
      country_id: 0,
      countries: undefined,
      countriesObject: undefined,
      slimOptions: {
        ratio: '1:1',
        instantEdit: true,
        buttonEditLabel: '',
        buttonRemoveLabel: '',
        size: '300, 300',
        maxFileSize: 0.3,
        didRemove: this.didRemove,
        didConfirm: this.assignFile
      },
      first_name: '',
      last_name: '',
      email: '',
      passwd: '',
      confirmation_passwd: '',
      role_id: 4,
      company_name: '',
      url_conditions: '',
      country: '',
      msgerror: '',
      logo: undefined,
      imageName: null,
      rules: [{ message: 'One lowercase letter required.', regex: /[a-z]+/ }, { message: 'One uppercase letter required.', regex: /[A-Z]+/ }, { message: '8 characters minimum.', regex: /.{8,}/ }, { message: 'Number required.', regex: /[0-9]+/ }, { message: 'One special character required.', regex: /\W|_/ }],
      errormsg: false
    }
  },
  validations () {
    return {
      first_name: { required, minLength: minLength(2), maxLength: maxLength(100) },
      company_name: { required, minLength: minLength(2), maxLength: maxLength(100) },
      last_name: { required, minLength: minLength(2), maxLength: maxLength(100) },
      email: {
        required: helpers.withMessage('Email is required.', required),
        email: helpers.withMessage('This email format is not valid.', email),
        maxLength: maxLength(100)
      },
      passwd: {
        required
      },
      confirmation_passwd: {
        required,
        sameAsPassword: helpers.withMessage('Your password fields dont match', sameAs(this.passwd))
      },
      url_condition: {}
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validationConfig: { $autoDirty: true },
  computed: {
    passwordValidation () {
      const errors = []
      for (const condition of this.rules) {
        if (!condition.regex.test(this.passwd)) {
          errors.push(condition.message)
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors }
      } else {
        return { valid: false, errors }
      }
    }
  },
  methods: {
    initialCheck () {
      var formErrors = document.getElementsByClassName('error-msg')
      if (formErrors.length > 0 || this.first_name === '' || this.last_name === '' || this.email === '' || this.passwd === '' || this.confirmation_passwd === '') {
        this.enabledNextButton = false
      } else {
        this.enabledNextButton = true
      }
    },
    finalCheck () {
      var formErrors = document.getElementsByClassName('error-msg')
      if (formErrors.length > 0 || this.company_name === '' || this.country_id === 0 || this.country_id === undefined || this.country === '') {
        this.enabledCreateButton = false
      } else {
        this.enabledCreateButton = true
      }
    },
    nextStep (evt) {
      this.step = false
    },
    goBack (evt) {
      this.step = true
      this.enabledCreateButton = false
    },
    signup (evt) {
      var data = new FormData()
      console.log(this.url_conditions)
      data.append('first_name', this.first_name)
      data.append('last_name', this.last_name)
      data.append('company_name', this.company_name)
      data.append('email', this.email)
      data.append('role_id', this.role_id)
      data.append('url_condition', this.url_conditions || null)
      if (this.logo !== undefined) {
        // 1 param - label, 2 - image, 3 - image name
         data.append('logo', this.logo, this.imageName)
       }
      data.append('pword', this.passwd)
      data.append('pword_confirmation', this.confirmation_passwd)
      data.append('country', this.country)
      this.axios
        .post(process.env.VUE_APP_API + '/session/signup', data)
        .then((response) => {
          this.$router.push({ path: '/verify', params: {} })
        })
        .catch((error) => {
          this.errormsg = true
          if (error.response) {
            // Request made and server responded
            this.msgerror = error.response.data.message
          }
        })
    },
    terms (evt) {
      // console.log('Terms modal initiated')
    },
    privacy (evt) {
      // console.log('Privacy policy modal initiated')
    },
    didRemove (data) {
      this.$refs.preview.classList.add('mb-2')
      this.logo = undefined
      this.finalCheck()
    },
    async assignFile (e) {
      this.$refs.preview.classList.remove('mb-2')
      this.logo = e.input.file
      this.imageName = e.input.name
      this.finalCheck()
    }
  },
  created () {
    this.axios
      .get(process.env.VUE_APP_API + '/aux/countries')
      .then((response) => {
        this.countries = Object.values(response.data.data)
        this.countriesObject = response.data.data
      })
  },
  mounted () {
    var _this = this
    eventHub.$on('set-country', (e) => {
      for (const [key, value] of Object.entries(this.countriesObject)) {
        if (value === e.country) {
          _this.country_id = parseInt(key)
          _this.country = value
          setTimeout(() => {
            _this.finalCheck()
          }, 100)
        }
      }
    })
  }
}
</script>
